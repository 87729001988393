/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react'
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import fetch from 'isomorphic-fetch'
import Layout from './src/components/layout'


import "./src/common/css/reset.css"
import "./src/common/css/style.css"


const httpLink = new HttpLink({
  uri: process.env.GRAPHQL_URL,
  fetch,
})

const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
})

const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

const wrapRootElement = ({ element }) => (
  <ApolloProvider client={apolloClient}>{element}</ApolloProvider>
)

export { wrapPageElement, wrapRootElement }