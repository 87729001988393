// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-to-cart-js": () => import("./../../../src/pages/addToCart.js" /* webpackChunkName: "component---src-pages-add-to-cart-js" */),
  "component---src-pages-change-info-complete-js": () => import("./../../../src/pages/changeInfoComplete.js" /* webpackChunkName: "component---src-pages-change-info-complete-js" */),
  "component---src-pages-change-info-confirm-js": () => import("./../../../src/pages/changeInfoConfirm.js" /* webpackChunkName: "component---src-pages-change-info-confirm-js" */),
  "component---src-pages-change-info-js": () => import("./../../../src/pages/changeInfo.js" /* webpackChunkName: "component---src-pages-change-info-js" */),
  "component---src-pages-contact-complete-js": () => import("./../../../src/pages/contactComplete.js" /* webpackChunkName: "component---src-pages-contact-complete-js" */),
  "component---src-pages-contact-confirm-js": () => import("./../../../src/pages/contactConfirm.js" /* webpackChunkName: "component---src-pages-contact-confirm-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-emag-js": () => import("./../../../src/pages/emag.js" /* webpackChunkName: "component---src-pages-emag-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-form-complete-js": () => import("./../../../src/pages/joinFormComplete.js" /* webpackChunkName: "component---src-pages-join-form-complete-js" */),
  "component---src-pages-join-form-confirm-js": () => import("./../../../src/pages/joinFormConfirm.js" /* webpackChunkName: "component---src-pages-join-form-confirm-js" */),
  "component---src-pages-join-form-js": () => import("./../../../src/pages/joinForm.js" /* webpackChunkName: "component---src-pages-join-form-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-order-complete-js": () => import("./../../../src/pages/orderComplete.js" /* webpackChunkName: "component---src-pages-order-complete-js" */),
  "component---src-pages-order-confirm-js": () => import("./../../../src/pages/orderConfirm.js" /* webpackChunkName: "component---src-pages-order-confirm-js" */),
  "component---src-pages-order-form-empty-js": () => import("./../../../src/pages/orderFormEmpty.js" /* webpackChunkName: "component---src-pages-order-form-empty-js" */),
  "component---src-pages-order-form-js": () => import("./../../../src/pages/orderForm.js" /* webpackChunkName: "component---src-pages-order-form-js" */),
  "component---src-pages-order-login-js": () => import("./../../../src/pages/orderLogin.js" /* webpackChunkName: "component---src-pages-order-login-js" */),
  "component---src-pages-remind-number-complete-js": () => import("./../../../src/pages/remindNumberComplete.js" /* webpackChunkName: "component---src-pages-remind-number-complete-js" */),
  "component---src-pages-remind-number-confirm-js": () => import("./../../../src/pages/remindNumberConfirm.js" /* webpackChunkName: "component---src-pages-remind-number-confirm-js" */),
  "component---src-pages-remind-number-js": () => import("./../../../src/pages/remindNumber.js" /* webpackChunkName: "component---src-pages-remind-number-js" */),
  "component---src-pages-search-result-js": () => import("./../../../src/pages/searchResult.js" /* webpackChunkName: "component---src-pages-search-result-js" */),
  "component---src-pages-userpage-js": () => import("./../../../src/pages/userpage.js" /* webpackChunkName: "component---src-pages-userpage-js" */),
  "component---src-templates-news-list-template-js": () => import("./../../../src/templates/news-list-template.js" /* webpackChunkName: "component---src-templates-news-list-template-js" */),
  "component---src-templates-training-template-js": () => import("./../../../src/templates/training-template.js" /* webpackChunkName: "component---src-templates-training-template-js" */),
  "component---src-templates-wp-book-template-js": () => import("./../../../src/templates/wp-book-template.js" /* webpackChunkName: "component---src-templates-wp-book-template-js" */),
  "component---src-templates-wp-page-template-js": () => import("./../../../src/templates/wp-page-template.js" /* webpackChunkName: "component---src-templates-wp-page-template-js" */),
  "component---src-templates-wp-post-template-js": () => import("./../../../src/templates/wp-post-template.js" /* webpackChunkName: "component---src-templates-wp-post-template-js" */),
  "component---src-templates-wp-publication-template-js": () => import("./../../../src/templates/wp-publication-template.js" /* webpackChunkName: "component---src-templates-wp-publication-template-js" */)
}

