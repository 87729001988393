import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Footer = ({ siteTitle }) => (

  <footer>
    <div className="footer_menu">
      <div className="footer_menu_box">
        <ul>
          <li><Link to="/" className="footer_menu_parent">HOME</Link></li>
        </ul>
        <ul>
          <li><Link to="/" className="footer_menu_parent">協会について</Link>
            <ul>
              <li><Link to="/outline">協会の概要</Link></li>
              <li><Link to="/history">沿革</Link></li>
              <li><Link to="/officer">役員・理事</Link></li>
              <li><Link to="/organization">組織</Link></li>
              <li><Link to="/membership3">会員構成</Link></li>
              <li><Link to="/business">業務内容</Link></li>
              <li><Link to="/awards">表彰</Link></li>
              <li><Link to="/education">資格認定・教育</Link></li>
              <li><Link to="/release">公開情報</Link></li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="footer_menu_box wide">
        <ul>
          <li><Link to="/training" className="footer_menu_parent">保安講習会</Link>
            <ul>
              <li><Link to="/training/jrnorth">JR北海道</Link></li>
              <li><Link to="/training/jreasttokyo">JR東日本［資格認定講習］</Link></li>
              <li><Link to="/training/jreastyokohama">JR東日本［10条在・運転］</Link></li>
              <li><Link to="/training/jreasthachioji">JR東日本［10条在・保守］</Link></li>
              <li><Link to="/training/jreastomiya">JR東日本［10条幹・列車見張員］</Link></li>
              <li><Link to="/training/jrtokai">JR東海</Link></li>
              <li><Link to="/training/jrwest">JR西日本</Link></li>
              <li><Link to="/training/jrshikoku">JR四国</Link></li>
              <li><Link to="/training/jrkyusyu">JR九州</Link></li>
              <li><Link to="/training/seibu">西武鉄道</Link></li>
              <li><Link to="/training/tokyu">東急電鉄</Link></li>
              <li><Link to="/training/odakyu">小田急電鉄</Link></li>
              <li><Link to="/training/tx">首都圏新都市鉄道</Link></li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="footer_menu_box">
        <ul>
          <li><Link to="/branch" className="footer_menu_parent">支部活動</Link>
            <ul>
              <li><Link to="/branch#branch_hokkaido">北海道支部</Link></li>
              <li><Link to="/branch#branch_tohoku">東北支部</Link></li>
              <li><Link to="/branch#branch_kanto">関東支部</Link></li>
              <li><Link to="/branch#branch_chubu">中部支部</Link></li>
              <li><Link to="/branch#branch_kansai">関西支部</Link></li>
              <li><Link to="/branch#branch_chugoku">中国支部</Link></li>
              <li><Link to="/branch#branch_shikoku">四国支部</Link></li>
              <li><Link to="/branch#branch_kyusyu">九州支部</Link></li>
            </ul>
          </li>
        </ul>
        <ul>
          <li><Link to="/publication" className="footer_menu_parent">出版・刊行物</Link></li>
        </ul>
      </div>
      <div className="footer_menu_box">
        <ul>
          <li><Link to="/member" className="footer_menu_parent">入会のご案内</Link></li>
          <li><Link to="/userpage" className="footer_menu_parent">会員のページ</Link>
            <ul>
              <li><Link to="/login">会員ログイン</Link></li>
              <li><Link to="/emag">協会誌電子版</Link></li>
              <li><Link to="/search_book">協会誌/図書検索</Link></li>
              <li><Link to="/education_member">会員様向け教育</Link></li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="footer_menu_box">
        <ul>
          <li><Link to="/ad">広告募集</Link></li>
          <li><Link to="/faq">よくあるご質問</Link></li>
          <li><Link to="/access">アクセスマップ</Link></li>
          <li><Link to="/sitemap">サイトマップ</Link></li>
          <li><Link to="/privacy">プライバシーポリシー</Link></li>
          <li><Link to="/link">外部リンク</Link></li>
          <li><Link to="/contact">お問い合わせ</Link></li>
        </ul>
      </div>
    </div>
    <div className="copyright">
      <p><span>一般社団法人日本鉄道施設協会</span><br />2021 Japan Railway Civil Engineering Association all rights reserved.</p>
    </div>
  </footer>
)


Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
