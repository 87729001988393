import * as React from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import $ from 'jquery'
import 'jquery.cookie'

// CSS読み込み

// 画像読み込み
import Logo from "../common/images/logo.png"
import FontMinus from "../common/images/font_minus.png"
import FontPlus from "../common/images/font_plus.png"
import SearchBtn from "../common/images/search_btn.png"

class Header extends React.Component {
  state = {
    searchText: ""
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSearch = event => {
    event.preventDefault()
    navigate(`/searchResult?text=${this.state.searchText}`)
    return null
  }


  componentDidMount = () => {
    $('.nav_btn').on('click', function () {
      if ($(this).hasClass('open')) {
        $(this).removeClass('open');
        $('nav').slideUp();
      } else {
        $(this).addClass('open');
        $('nav').slideDown();
      }
    });


    // ハンバーガーメニューのアクション
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      $('nav ul li a').on('click', function () {
        $('.nav_btn').click()
      })
    }

    var fs = $.cookie('fontSize');
    if (fs) {
      if (fs === "fontSize_plus") {
        $(this).css("opacity", ".7");
        $("html").css("font-size", "95%");
        $("#fontSize_minus").css("opacity", "1");
      } else if (fs === "fontSize_minus") {
        $(this).css("opacity", ".7");
        $("html").css("font-size", "62.5%");
        $("#fontSize_plus").css("opacity", "1");
      }
    }

    $(".fontsize_btn").on("click", function () {
      var thisSize = this.id;
      $.cookie('fontSize', thisSize);
      if (thisSize === "fontSize_plus") {
        $(this).css("opacity", ".7");
        $("html").css("font-size", "95%");
        $("#fontSize_minus").css("opacity", "1");
      }
      if (thisSize === "fontSize_minus") {
        $(this).css("opacity", ".7");
        $("html").css("font-size", "62.5%");
        $("#fontSize_plus").css("opacity", "1");
      }
    });
  }

  render = (siteTitle) => {
    return (
      <header id="header">
        <div className="header_inner">
          <div className="header_logo">
            <h1>
              <Link to="/"><img src={Logo} alt="JRCEA 一般社団法人 日本鉄道施設協会" className="imgFit" /></Link>
            </h1>
          </div>
          <div className="header_menu">
            <ul>
              <li className="header_menu_fontsize">表示サイズ
                <div id="fontSize_minus" className="fontsize_btn">
                  <img src={FontMinus} alt="ー" className="imgFit"></img>
                </div>
                <div id="fontSize_plus" className="fontsize_btn">
                  <img src={FontPlus} alt="＋" className="imgFit"></img>
                </div>
              </li>
              <li>
                <Link to="/contact">お問い合わせ</Link>
              </li>
              <li>
                <Link to="/userpage">会員のページ</Link>
              </li>
              <li className="header_menu_search">
                <form name="site_search" onSubmit={this.handleSearch}>
                  <input type="text" name="searchText" value={this.state.MemberNo} onChange={this.handleInputChange} placeholder="サイト内検索"></input>
                  <input type="image" src={SearchBtn} alt="検索"></input>
                </form>
              </li>
            </ul>
            <button className="nav_btn">
              <svg viewBox="0 0 44 44">
                <title id="btntitle">ナビゲーションボタン</title>
                <g>
                  <line id="nbt1" fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="11" y1="12" x2="33" y2="12"></line>
                  <line id="nbt2" fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="11" y1="22" x2="33" y2="22"></line>
                  <line id="nbt3" fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="11" y1="32" x2="33" y2="32"></line>
                </g>
              </svg>
            </button>
          </div>
        </div>
        <nav>
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li><Link to="/">協会について</Link>
              <ul className="menu-second-lv">
                <li><Link to="/outline">協会の概要</Link></li>
                <li><Link to="/history">沿革</Link></li>
                <li><Link to="/officer">役員・理事</Link></li>
                <li><Link to="/organization">組織</Link></li>
                <li><Link to="/membership3">会員構成</Link></li>
                <li><Link to="/business">業務内容</Link></li>
                <li><Link to="/awards">表彰</Link></li>
                <li><Link to="/education">資格認定・教育</Link></li>
                <li><Link to="/release">公開情報</Link></li>
              </ul>
            </li>
            <li><Link to="/training">保安講習会</Link>
              <ul className="menu-second-lv">
                <li><Link to="/training/jrnorth">JR北海道</Link></li>
                <li><Link to="/training/jreasttokyo">JR東日本［資格認定講習］</Link></li>
                <li><Link to="/training/jreastyokohama">JR東日本［10条在・運転］</Link></li>
                <li><Link to="/training/jreasthachioji">JR東日本［10条在・保守］</Link></li>
                <li><Link to="/training/jreastomiya">JR東日本［10条幹・列車見張員］</Link></li>
                <li><Link to="/training/jrtokai">JR東海</Link></li>
                <li><Link to="/training/jrwest">JR西日本</Link></li>
                <li><Link to="/training/jrshikoku">JR四国</Link></li>
                <li><Link to="/training/jrkyusyu">JR九州</Link></li>
                <li><Link to="/training/seibu">西武鉄道</Link></li>
                <li><Link to="/training/tokyu">東急電鉄</Link></li>
                <li><Link to="/training/odakyu">小田急電鉄</Link></li>
                <li><Link to="/training/tx">首都圏新都市鉄道</Link></li>
              </ul>
            </li>
            <li><Link to="/branch">支部活動</Link>
              <ul className="menu-second-lv">
                <li><Link to="/branch#branch_hokkaido">北海道支部</Link></li>
                <li><Link to="/branch#branch_tohoku">東北支部</Link></li>
                <li><Link to="/branch#branch_kanto">関東支部</Link></li>
                <li><Link to="/branch#branch_chubu">中部支部</Link></li>
                <li><Link to="/branch#branch_kansai">関西支部</Link></li>
                <li><Link to="/branch#branch_chugoku">中国支部</Link></li>
                <li><Link to="/branch#branch_shikoku">四国支部</Link></li>
                <li><Link to="/branch#branch_kyusyu">九州支部</Link></li>
              </ul>
            </li>
            <li><Link to="/publication">出版・刊行物</Link></li>
            <li><Link to="/member">入会のご案内<br />会員情報変更</Link>
            </li>
            <li><Link to="/userpage">会員のページ</Link>
              <ul className="menu-second-lv">
                <li><Link to="/login">会員ログイン</Link></li>
                <li><Link to="/emag">協会誌電子版</Link></li>
                <li><Link to="/search_book">協会誌/図書検索</Link></li>
                <li><Link to="/education_member">会員様向け教育</Link></li>
              </ul>
            </li>
            <li className="forSP"><Link to="/access">アクセスマップ</Link></li>
            <li className="forSP"><Link to="/sitemap">サイトマップ</Link></li>
          </ul>
        </nav>

      </header>

    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
